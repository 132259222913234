@use "../../../partials/colours" as *;

.new-product {
  background-color: $cream;
  display: flex;
  flex-direction: column;

  &__title {
    margin: 2rem 9rem;
  }
  button {
    padding: 1rem;
    margin: 0 9rem;
  }

  &__form {
    margin: 4rem 12rem;
    display: flex;
    flex-direction: column;
    label {
      margin-top: 1rem;
      font-size: 1.6rem;
    }

    button {
      padding: 2rem;
      margin: 2rem 0;
    }

    input,
    textarea {
      border-radius: 20px;
      border: 1px solid $cream;
      padding: 1rem 2rem;
    }

    textarea {
      height: 15rem;
      max-width: 100%;
    }
  }
  &__preview-img {
    width: 10rem;
    height: 10rem;
  }
}

.modal {
  position: sticky;
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.5);

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;

    & > * {
      margin: 3rem;
    }
  }
}

.image-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &__image {
    margin: 1rem;
    max-height: 10rem;
    max-width: 10rem;
    aspect-ratio: 1/1;
  }
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.image-preview {
  max-height: 150px;
  max-width: 150px;
  aspect-ratio: 1/1;
}
