.admin{
    display: flex;
    height: 40vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__buttons {
        button {
            margin: 1rem;
            padding: 1rem;
            font-size: 2rem;
        }
    }
}