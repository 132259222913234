@use "../../partials/colours" as *;

.contact{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__hero{
        width: 100%;
    }

    &__title{
        margin: 4rem;
        text-decoration:underline;

    }

    &__tagline {
        width: 60%;
        font-size: 2.8rem;
        line-height: 3.2rem;
        margin: 4rem 0;
        text-align: center;
    }

    &__main{
        font-size: 1.8rem;
        width: 70%;
        text-align: center;
        margin: 2rem;
    }
}
.contact-container {
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.contact-box {
    width: 25rem;
    border: 4px black dashed;
    padding: 2rem;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 1rem;
    // background-color: $cream;

    h4{
        font-size: 1.4rem;
    }
    
    &:hover {
        border: 4px black solid;
        transition: ease 0.5s 2s;


    }

    &__image {
        padding: 1rem;
        border: 4px black solid;
        border-radius: 50%;
        width: 6rem;
    }

    &:hover > &__image {
        width: 10rem;
        border: 4px black dashed;
    }
}