@use "../../partials/colours" as *;
@use "../../partials/breakpoints" as *;

.searchbar {

    z-index: 1000;
    &__form {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        
    }
    
    
    &__textbox {
        z-index: 1000;
        font-size: 1.8rem;
            width: 90vw;
            height: 4rem;
            margin: 2rem 0;
            padding: 0.5rem;
            border: 2px solid $brown;
            border-radius: 4px;
    }
    
    
    &--hide {
        display: none;
    }
    
    &--show {
        z-index: 1000;
        background-color: white;
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 5rem;
        margin: 0 auto;
    }

    @include desktab {

        &--show {

                display: inline-block;
                position: absolute;
                width: 100%;
                top: 16rem;
                margin: 0 auto;
            }

        
        &__textbox{
            z-index: 1000;
    
            font-size: 2.4rem;
            width: 40vw;
            height: 4.5rem;
            margin: 2rem 0;
            padding: 0.5rem;
            border: 2px solid $brown;
            border-radius: 4px;
    
        }
    }
}