// Mobile Font Style //
@use "./breakpoints" as *;
@use "./fonts" as *;
@use "./colours" as *;

h1 {
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 2.6rem;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 2.6rem;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 2.4rem;
}

h4 {
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 2.6rem;
}

p {
  font-size: 1.2rem;
  line-height: 2.2rem;
  letter-spacing: 0.5px;
  font-weight: 300;
  font-family: CrimsonPro;
}

// Desktop and Tablet Font Style

@include desktab {
  h1 {
    font-size: 2.2rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 2.8rem;
  }

  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 2.8rem;
  }

  h3 {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 2.6rem;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 2.6rem;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: 0.5px;
    font-weight: 400;
    font-family: CrimsonPro;
  }
}
