@use "../../../partials/breakpoints" as *;
@use "../../../partials/colours" as *;

.desknav {
    display: none;
        cursor: pointer;

    @include desktab {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        margin: 2rem 0;

        &__links {
            display: flex;
            justify-content: center;
            align-items: center;

        }

        
        &__link {
            list-style: none;
            padding: 1.4rem 2rem;
            text-decoration: none;
            transition: text-decoration ease 1s;
            
            
            &:hover{
                text-decoration: underline;
                text-decoration-color: $brown ;
                text-decoration-thickness: 4px ;
                text-underline-offset: 4px;
            }
        }
        
        &__search-icon {
            height: 3rem;
        }
        
        

        
    }

    @include tablet {
            &__upper {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            
                &__contacts {
                    margin-right: 3rem;
            
                    h4 {
                        font-size: 1rem;
                    }
            
                }
            
                &__contact-container {
                    display: flex;
                    margin: 1rem 0;
                }
            
                &__icons {
                    width: 2rem;
                    height: 2rem;
                    margin: 0 1rem;
            
                }
            
             

                &__side-container {
                    margin-left: 3rem;
                    min-width: 217.141px;


                }

                &__side-logo {

                    margin-left: 3rem;
                    height: 4rem;
                }
                &__logo {
                    height: 4rem;
                }
}

    @include desktop {

        &__upper{
         width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__contacts {
            margin-right: 4rem;
    
            h4 {
                font-size: 1.2rem;
            }
    
        }
    
        &__contact-container {
            display: flex;
            margin: 1rem 0;
        }
    
        &__icons {
            width: 3rem;
            margin: 0 1rem;
    
        }
    
        &__side-container {
            margin-left: 4rem;
            min-width: 259.766px;
    
         
        }
        &__side-logo {

            margin-left: 3rem;
            height: 8rem;
        }

        &__logo{
            height:8rem;
        }

    }
}