@use "../../../partials/breakpoints" as *;
@use "../../../partials/colours" as *;



.navbar {


    &__logo {
            width: 23rem;
        }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1.4rem 1rem;
    }

    &__header-icon{
        width: 3.6rem;
        height: auto;
    }

    &__menu {

        &--hide {
            display: none;
        }

        &--show {
            position: absolute;
            display: inline-block;
            background-color: $white;
            width: 65vw;
            z-index: 100;
        }
    }

    &__menu-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem;
    }

    &__chevron {
        height: 3rem;
        width: auto;
    }

    @include desktab {
        display: none;
    }
}