@use "../../partials/colours" as *;
@use "../../partials/breakpoints" as *;

.footer {
  width: 100%;
  border-top: 4px solid $cream;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-size: 1.2rem;
  }

  &__top {
    width: 80vw;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: flex-start;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__logo {
    width: 60vw;
  }

  &__section-title {
    margin: 1.5rem 0;
    text-decoration: underline;
  }

  &__icons {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }

  &__contact-container {
    display: flex;
    align-items: flex-start;
    margin-right: 2rem;
  }

  &__rights-reserved {
    margin-top: 2rem;
  }

  &__links {
    display: flex;
    flex-direction: column;
  }

  &__links-container {
    margin-right: 2rem;
  }

  &__social {
    margin-right: 2rem;
  }

  @include desktab {
    &__top {
      width: 80vw;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    &__logo {
      width: 20vw;
    }

    &__section-title {
      margin: 1.5rem 0;
      text-decoration: underline;
    }

    &__icons {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }

    &__contact-container {
      display: flex;
      align-items: flex-start;
    }

    &__links {
      flex-direction: row;
    }
  }
}
