@use "../../../partials/colours" as *;

.dropdown-item{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    border-bottom: 4px solid transparent;

    transition: border-bottom ease 0.5s;
    &:hover {
        border-bottom: 4px solid $brown;
    }
    &__item {
        margin: 0.5rem 2rem;
        text-align: center;
        z-index: 1000;
        
    }
    
    &__image {
        width: 10rem;
        height: 10rem;
        margin: 0.5rem 2rem;
    }
}
