@use "../../partials/breakpoints" as *;

.home {
    z-index: 10;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__sale-banner{
        display: flex;
        justify-content: center;
        width: 100%;
       align-self: center;

       @include desktab {
        width: 90%;
       }
    }
}