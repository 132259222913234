.sell-yours{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__content {
        width: 70%;
        display: flex;
        flex-direction: column;


    }


    &__hero{
        width: 100%;
    }

    &__title{
        margin: 4rem;
        text-decoration:underline;

    }

    &__subtitle {

        align-self: flex-start;
    }

    &__tagline {

        margin: 4rem 0;
        font-size: 2.4rem;
        text-align: center;
    }

    &__main{
        font-size: 1.8rem;

        margin: 2rem 0;



    }
    &__contact {
        margin-bottom: 4rem;
        width: fit-content;

        button{
            padding: 1rem;
        }
    }
}