@use "./fonts" as *;

html {
    font-size: 62.5%;
    padding: 0;
}

body {
    box-sizing: border-box;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    font-family: Montserrat;
}

a {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: none;
}