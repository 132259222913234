@use "../../../partials/colours" as *;

.dropdown-item-mobile{
    padding: 1rem;
    display: flex;
    flex-direction: column;

    z-index: 1000;




}
