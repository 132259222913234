@font-face {
    font-family: CormorantGaramond;
    src: url(../assets/fonts/Cormorant_Garamond/CormorantGaramond-Bold.ttf) format("truetype"),
url(../assets/fonts/Cormorant_Garamond/CormorantGaramond-Regular.ttf) format("truetype"),
url(../assets/fonts/Cormorant_Garamond/CormorantGaramond-Light.ttf) format("truetype"),
}

@font-face {
    font-family: Montserrat;
    src: url(../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format('truetype');
}
@font-face {
    font-family: CrimsonPro;
    src: url(../assets/fonts/Crimson_Pro/CrimsonPro-VariableFont_wght.ttf) format('truetype'),
     url(../assets/fonts/Crimson_Pro/CrimsonPro-Italic-VariableFont_wght.ttf) format('truetype')
}