@use "../../partials/breakpoints" as *;
@use "../../partials/colours" as *;

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: fit-content;
  padding: 3rem;
  background-color: $white;
  height: 55rem;

  margin: 2rem;
  justify-content: space-between;

  &__image {
    height: 20rem;
    aspect-ratio: 1/1;
  }

  &__title {
    margin: 1rem 0;
    text-align: center;
  }

  &__gem {
    text-align: center;
  }

  &__metal {
    margin: 0;
  }

  &__rrp {
    font-size: 1.4rem;
    color: grey;
    margin: 1rem;
    text-align: center;
  }

  &__sale-price {
    margin: 1rem 0;
    font-size: 2.4rem;
    font-weight: bold;
  }

  &__price {
    margin: 1rem 0;
  }

  &__button {
    color: $white;
    background-color: $black;
    width: 100%;
    height: 4rem;
    border: none;
  }

  @include desktop {
    &__button {
      color: transparent;
      background-color: transparent;
      width: 100%;
      height: 4rem;
      border: none;
    }

    &:hover > &__button {
      color: $black;
      background-color: $white;
      border: black 2px solid;

      &:hover {
        color: white;
        background-color: $black;
        transition: ease 0.3s;
      }
    }
  }

  @include tablet {
    width: 45%;
  }

  @include desktop {
    width: 20%;
  }
}
