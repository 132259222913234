@use "../../../partials/breakpoints" as *;
@use "../../../partials/colours" as *;

.dropdown {
    display: none;
    cursor: pointer;

    @include desktab  {
    
        
            &--hide {
                display: none;
            }
        
            &--show {
                position: absolute;
                    display: inline-block;
                    background-color: $white;
                    width: 100vw;
                    height: 22rem;
                    top: 15.3rem;
                    z-index: 10;
                    box-shadow: 0 4px $cream;
         

            }

            &__container{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 2rem 0;
            }

            &__slider {
                width: 70%;
            }
    }

    
}

// .desktab {
//     display: none;
// }
