@use "../../../partials/breakpoints" as *;

.slider {

    margin: 0 auto;
    z-index: 1;
    width: 100%;
    
    &__container {
        width: 100%;
        z-index: 1;
    }
    
    &__img {
        z-index: 1;
        margin: 0 auto;
        width: 100%;
        height: auto;
    }
    

    @include desktab {

        width: 90%;

        
    }
}