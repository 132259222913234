//Text Colors

$black: #000000;
$White: #FFFFFF;
$brown: #3E3E3E;

//Background Colors

$white: #FFFFFF;
$beige: #F9F9F9;
$cream: #F6F4F0;

//Decorations Colors

$red: #C10117


