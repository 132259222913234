.header{

    display: flex;
    justify-content: center;
    margin: 0 8rem;

    &__title {
        width: 40%;
        align-self: flex-start;
    }

    &__img {
        width: 10%;
        align-self: flex-start;
    }
    &__sku {
        width: 15%;
        align-self: flex-start;
    }
    &__date {
        width: 25%;
    }
}

.delete-modal {
    margin: 0 4rem;
    text-align: right;

    button {
        margin: 1rem 2rem;
        padding: 1rem;
    }
}

.navb {
    margin: 1rem;
    padding: 0.5rem;
}

.inv-search{
    margin: 0 1rem;
    padding: 1rem;
}