@use "../../../partials/breakpoints" as *;
@use "../../../partials/colours" as *;


.sale-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 3rem;
    background-color: $white;
    justify-content: space-between;

    &__image {
        height: 20rem;
        aspect-ratio: 1/1;
    }

    &__title {
        margin: 1rem 0;
        text-align: center;
    }
    

    &__metal {
        margin: 1rem 0;
    }
    
    &__price {
        margin: 1rem 0;
        color: $red;
        text-decoration: line-through;
    }
    &__gem {
        text-align: center;
    }

         &__rrp {
             font-size: 1.4rem;
             color: grey;
             margin: 1rem;
         }
    
    &__sale-price {
        margin: 1rem 0;
        font-size: 2rem;
    }

         &__button {
             color: $white;
             background-color: $black;
             width: 100%;
             height: 4rem;
             border: none;
    
         }
    
    
    @include desktop {
        &__button {
            color: transparent;
            background-color: transparent;
            width: 100%;
            height: 4rem;
            border: none;
    
        }
    
        &:hover >  &__button {
    
                color: $black;
                background-color: $white;
                border: black 2px solid;
    
    
                &:hover {
                    color: white;
                    background-color: $black;
                    transition: ease 0.3s;
                }
        }

    }
}