@use "../../partials/breakpoints" as *;
@use "../../partials/colours" as *;

.product-page {

    flex-direction: column;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 100vw;

    &__details {
        width: 80%;
    }

    &__details > * {
        margin: 1rem 0rem;
        
    }

    &__enquire {
        width: 100%;
        background-color: $black;
        color: $white;
        height: 5rem;
        font-size: 1.8rem;
        margin: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    &__icon {
        margin: 0 2rem;
        width: 3rem;
        height: auto;
        filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(100deg) brightness(100%) contrast(100%);
        
    }

    &__sale-price{
        color: $red;
        text-decoration: line-through;
    }

    &__rrp {
        color: rgba(0, 0, 0, 0.5);
    }

    &__images {
        max-width: 100%;
    }



    @include desktab {
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        max-width: 1400px;
        margin: 0 auto;

        &__enquire {
            width: 90%;

            &:hover {
                background-color: rgba(0, 0, 0, 0.8);
                transition: ease 0.4s;
            }
        }

        &__images {
            margin-top: 6rem;
            width: 40%;
            // max-height: 200px;
        }

        &__details  {
            width: 45%;
        }

        &__details > * {
            margin: 3rem 0;
        }
    }
}