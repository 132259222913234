.about{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    &__hero{
        width: 100%;
    }

    &__title{
        margin: 4rem;
        text-decoration:underline;

    }

    &__tagline {
        width: 70%;
        margin: 4rem 0;
        font-size: 2.4rem;
        text-align: center;
    }

    &__main{
        font-size: 1.8rem;
        width: 70%;
        // text-align: center;
        margin: 2rem 0;
        align-self: center;


    }
}