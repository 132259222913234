.dropdown {
    z-index: 100;
    &--show{
        z-index: 100;
        display:flex;
        background-color: white;
        flex-direction: column;
    }

    &--hide{
        display: none;
    }
}