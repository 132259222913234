.favourites {
    width: 90%;
    margin: 2rem auto;

    &__header {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 3rem 0;
    }

}
