@use "../../partials/colours" as *;

.products {
    display: flex;
    flex-direction: column;
    background-color: $cream;
    
    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__title {
        margin: 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: underline;
    }

    &__none {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50vh;
    }

    &__no-prods{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
       width: 70%;
       margin: 2rem 1rem;
       height: fit-content;
    }

    &__enquire-container {
        width: 60%;
    }

    &__enquire {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1rem 1rem;
        padding: 1rem;
        width: 100%;

        &:hover {
            background-color: white;
            transition: ease 0.4s;
        }
        
    }

    &__icon {
        width: 30px;
        height: 30px;
        margin-right: 2rem;
    }

}