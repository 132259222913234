@use "../../partials/breakpoints" as *;
@use "../../partials/colours" as *;

.subscribe {
  height: fit-content;
  width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $cream;

  &__title {
    &--show {
      margin: 1.5rem 1.5rem;
    }

    &--hide {
      display: none;
    }
  }

  &__form {
    &--show {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &--hide {
      display: none;
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  &__inputs {
    width: 100%;
    border-radius: 5px;
    border: transparent;
    background-color: $beige;
    padding: 1rem;
    margin: 0.5rem;
  }

  &__button {
    width: 90%;
    padding: 1rem;
    background-color: black;
    color: white;
    margin: 1.5rem;
  }

  &__signed-up {
    &--show {
      display: none;
    }

    &--hide {
      display: flex;
      height: 20vh;
      align-items: center;
    }
  }

  @include desktab {
    margin-top: 3rem;
    height: 40vh;
    flex-direction: row;
    // justify-content: space-evenly;

    &__form--show {
      width: 30vw;
    }

    &__title--show {
      width: 60vw;
      font-size: 3.2rem;
      line-height: 5rem;
    }

    &__inputs {
      font-size: 2rem;
    }

    &__button {
      font-size: 2rem;
    }
  }
}
