
$tablet-min: 768px;
$tablet-max: 1279px;
$desktop-min: 1280px;

@mixin tablet {
    @media only screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: $desktop-min) {
        @content;
    }
}

@mixin desktab {
    @media only screen and (min-width: $tablet-min) {
        @content;
    }
}

