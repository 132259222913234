@use "../../../partials/colours" as *;

.inventory-item{
    display: flex;
    margin: 1rem 3rem;
    padding: 1rem;
    justify-content: space-evenly;
    align-items: center;
    border: 2px solid black;
    background-color: $cream;

    &__title{ 
        width: 35%;
        margin: 1rem;

    }

    &__sku{
        width: 10%;
        margin: 1rem;

    }

    &__img {
        max-width: 10rem;
        aspect-ratio: 1/1;
    }

    &__date{
        width: 20%;
        margin: 1rem;
    }

    &__button {
        background-color: $beige;
        padding: 0.5rem;
    }
}